<mat-toolbar class="top-navbar position-fixed z-3">
    <mat-toolbar-row class="container-fluid d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
            @if (useWithAuth()) {
                <button mat-icon-button aria-label="Menu icon" (click)="toggleMenu()">
                    <mat-icon>menu</mat-icon>
                </button>
            }
            <img [ngSrc]="logoUrl"
                 width="130"
                 height="22"
                 alt="Attentive"
                 class="logo pointer mx-4"
                 routerLink="/app"
                 queryParamsHandling="merge">
        </div>

        @if (useWithAuth()) {
            <div class="d-none d-md-flex">
                @for (button of buttons$ | async; track button.link) {
                    @if (button.show$ | async) {
                        <button routerLinkActive="activeButton"
                                mat-button
                                [routerLink]="button.link"
                                queryParamsHandling="merge"
                                class="me-3">
                            {{button.label}}
                        </button>
                    }
                }

                <button mat-stroked-button [matMenuTriggerFor]="menu" color="primary">
                    <mat-icon>account_circle</mat-icon>
                    <span>{{ userName$ | async }}</span>
                    <mat-icon iconPositionEnd>expand_more</mat-icon>
                </button>

                <mat-menu #menu="matMenu" [overlapTrigger]="false">
                    <button mat-menu-item (click)="logout()">
                        <mat-icon class="text-danger">logout</mat-icon>
                        <span class="text-danger">Logout</span>
                    </button>
                </mat-menu>
            </div>

            <!-- Mobile Menu -->
            <button class="d-flex d-md-none"
                    mat-stroked-button
                    [matMenuTriggerFor]="menuMobile"
                    color="primary">
                <mat-icon>menu</mat-icon>
            </button>

            <mat-menu #menuMobile="matMenu">
                @for (button of buttons$ | async; track button.link) {
                    @if (button.show$ | async) {
                        <button mat-menu-item
                                [routerLink]="button.link"
                                queryParamsHandling="merge"
                                class="me-3">
                            {{button.label}}
                        </button>
                    }
                }

                <button mat-menu-item>
                    <mat-icon>account_circle</mat-icon>
                    <span>{{ userName$ | async }}</span>
                </button>

                <button mat-menu-item (click)="logout()">
                    <mat-icon class="text-danger">logout</mat-icon>
                    <span class="text-danger">Logout</span>
                </button>
            </mat-menu>
        }
    </mat-toolbar-row>
</mat-toolbar>
