export enum Application {
    MEMBER_MANAGER = 'MEMBER_MANAGER',
    PROPOSAL_MANAGER = 'PROPOSAL_MANAGER',
    HEALTHCARE_PORTAL = 'HEALTHCARE_PORTAL',
    HEALTHCARE_MOBILE = 'HEALTHCARE_MOBILE',
    PLATFORM_MANAGER = 'PLATFORM_MANAGER'
}

export const ApplicationLabels: { [key in Application]: string } = {
    [Application.MEMBER_MANAGER]: 'Member Manager',
    [Application.PROPOSAL_MANAGER]: 'Proposal Manager',
    [Application.HEALTHCARE_PORTAL]: 'Healthcare Portal',
    [Application.HEALTHCARE_MOBILE]: 'Healthcare Mobile',
    [Application.PLATFORM_MANAGER]: 'Platform Manager'
}

export const ApplicationIcons: { [key in Application]: string } = {
    [Application.MEMBER_MANAGER]: 'group',
    [Application.PROPOSAL_MANAGER]: 'description',
    [Application.HEALTHCARE_PORTAL]: 'local_hospital',
    [Application.HEALTHCARE_MOBILE]: 'phone_iphone',
    [Application.PLATFORM_MANAGER]: 'dashboard'
}


export interface IApplication {
    value: Application
    label: string
    description: string
    longDescription?: string
}
