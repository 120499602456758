import {BehaviorSubject, Observable, of, tap, throwError} from 'rxjs'
import {catchError} from 'rxjs/operators'
import {HttpClient, HttpHeaders} from '@angular/common/http'
import {API_URL_HEADER, ApiType} from '../http/interceptors/api-end-point.interceptor'
import {ICompany} from './design.types'
import {Injectable} from '@angular/core'

@Injectable({
    providedIn: 'root'
})
export class DesignService {
    private readonly DESIGN_STORAGE_KEY = 'app_design_settings'
    private designSettingsSubject = new BehaviorSubject<Partial<ICompany> | null>(null)

    public designSettings$ = this.designSettingsSubject.asObservable()

    constructor(private http: HttpClient) {
    }

    initializeDesignSettings(): Observable<Partial<ICompany>> {
        const cachedSettings = localStorage.getItem(this.DESIGN_STORAGE_KEY)

        if (cachedSettings) {
            const settings = JSON.parse(cachedSettings)
            this.designSettingsSubject.next(settings)
            // this.applyTheme(settings)
            return of(settings)
        }

        // If no cached settings, fetch from backend
        return this.fetchDesign()
    }

    private fetchDesign(): Observable<Partial<ICompany>> {
        const authzHeaders = new HttpHeaders().set(API_URL_HEADER, ApiType.AUTHZ)

        return this.http.get<ICompany>('companies/design', {
            headers: authzHeaders
        }).pipe(
            tap(settings => {
                localStorage.setItem(this.DESIGN_STORAGE_KEY, JSON.stringify(settings))
                this.designSettingsSubject.next(settings)
                // this.applyTheme(settings)
            }),
            catchError(error => {
                console.error('Failed to fetch design settings:', error)
                return throwError(() => error)
            })
        )
    }

    private applyTheme(settings: Partial<ICompany>) {
        // document.documentElement.style.setProperty('--primary-color', settings.design.colors.primary)
        // document.documentElement.style.setProperty('--secondary-color', settings.design.colors.secondary)
        // document.documentElement.style.setProperty('--accent-color', settings.design.colors.accent)
        // document.documentElement.style.setProperty('--font-family', settings.design.typography.fontFamily)
        //
        // if (settings.theme === 'dark') {
        //     document.body.classList.add('dark-theme')
        // } else {
        //     document.body.classList.remove('dark-theme')
        // }
    }
}
