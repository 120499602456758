
import {EnvTypes} from './env.types'

export const environment: EnvTypes = {
  production: false,
  version: '0.100.4',
  API_URL: 'https://members-back-end-dev.onrender.com/',
  AUTH_API_URL: 'https://auth-service-e5by.onrender.com/api/v1/',
  AUTHZ_API_URL: 'https://authz-service.onrender.com/api/v1/',
  BUILD_TIME: '2025-01-27T10:08:49.790Z',
}
