import {Application} from '../../../pages/user-manager/tab-applications/application.types'
import {HttpInterceptorFn} from '@angular/common/http'

export const APP_TYPE = Application.MEMBER_MANAGER
export const APPLICATION_TYPE_HEADER = 'X-Application-Type'

export const headerInterceptor: HttpInterceptorFn = (req, next) => {
    if (!req.headers.has(APPLICATION_TYPE_HEADER)) {
        const modifiedReq = req.clone({
            headers: req.headers.set(APPLICATION_TYPE_HEADER, APP_TYPE)
        })
        return next(modifiedReq)
    }

    return next(req)
}
