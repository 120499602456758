import {inject} from '@angular/core'
import {CanActivateChildFn, CanActivateFn, Router} from '@angular/router'
import {AuthService} from './auth.service'
import {map, take} from 'rxjs'

export const AuthenticationGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router = inject(Router)
    const authService = inject(AuthService)

    return authService.user$.pipe(
        take(1), // Take only the current value and complete
        map(user => {
            if (user) {
                return true
            }

            const redirectUrl = encodeURIComponent(state.url)
            router.navigate(['/auth'], {
                replaceUrl: true,
                queryParams: {redirectUrl}
            })
            return false
        })
    )
}
