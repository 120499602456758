import {HttpInterceptorFn} from '@angular/common/http'
import {environment} from '../../../../environments/environment'

export const API_URL_HEADER = 'X-Api-Type'

export enum ApiType {
    MAIN = 'main',
    AUTH = 'auth',
    AUTHZ = 'authz'
}

export const apiEndpointInterceptor: HttpInterceptorFn = (req, next) => {
    if (req.headers.get('skipApiEndPointInterceptor')) {
        return next(req)
    }

    // Get API type from header, default to main API
    const apiType = req.headers.get(API_URL_HEADER) || ApiType.MAIN

    // Remove the routing header to keep request clean
    const headers = req.headers.delete(API_URL_HEADER)

    // Determine base URL based on API type
    let baseUrl: string
    switch (apiType) {
        case ApiType.AUTH:
            baseUrl = environment.AUTH_API_URL
            break
        case ApiType.AUTHZ:
            baseUrl = environment.AUTHZ_API_URL
            break
        case ApiType.MAIN:
        default:
            baseUrl = environment.API_URL
            break
    }

    // Clone request with new URL and cleaned headers
    const modifiedReq = req.clone({
        url: baseUrl + req.url,
        headers
    })

    return next(modifiedReq)
}
