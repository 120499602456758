import {AuthService} from './auth/auth.service'
import {inject} from '@angular/core'
import {firstValueFrom} from 'rxjs'
import {DesignService} from './@core/design/design.service'

export function appInitializer() {
    const authService = inject(AuthService)
    const designService = inject(DesignService)

    return () => {
        // Return a promise that resolves when initialization is complete
        return new Promise<void>(async (resolve) => {
            try {
                // Initialize design settings (will fetch only if not cached)
                await firstValueFrom(designService.initializeDesignSettings())

                // Check if we have a token before attempting to fetch user
                const token = await firstValueFrom(authService.token$)

                if (token) {
                    // If we have a token, fetch the user data
                    await firstValueFrom(authService.fetchMe())
                }

                resolve()
            } catch (error) {
                // If there's an error, we should still resolve but log the error
                console.error('Error during app initialization:', error)
                resolve()
            }
        })
    }
}
