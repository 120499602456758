import {
    HttpEvent,
    HttpHandlerFn,
    HttpRequest,
    HttpInterceptorFn
} from '@angular/common/http'
import {Observable} from 'rxjs'
import {MEMBER_MANAGER_TOKEN} from '../../../shared/consts/storage.const'
import {API_URL_HEADER} from './api-end-point.interceptor'

export const authInterceptor: HttpInterceptorFn = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    // Skip token injection for authentication endpoints
    if (
        req.headers.has('skipApiEndPointInterceptor') ||
        req.headers.get(API_URL_HEADER) === 'auth' ||
        req.url.includes('/auth/login') ||
        req.url.includes('/auth/refresh')
    ) {
        return next(req)
    }

    const token = localStorage.getItem(MEMBER_MANAGER_TOKEN)

    if (!token) {
        return next(req)
    }

    // Clone the request and add the authorization header
    const authReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`)
    })

    return next(authReq)
}
