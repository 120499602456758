import {HttpClient, HttpErrorResponse, HttpHeaders, HttpInterceptorFn, HttpRequest} from '@angular/common/http'
import {inject} from '@angular/core'
import {Router} from '@angular/router'
import {Observable, throwError, BehaviorSubject, filter, take, switchMap, catchError} from 'rxjs'
import {MEMBER_MANAGER_REFRESH, MEMBER_MANAGER_TOKEN} from '../../../shared/consts/storage.const'
import {API_URL_HEADER, ApiType} from './api-end-point.interceptor'

// Flag to prevent multiple token refresh calls
let isRefreshing = false
const tokenRefreshQueue = new BehaviorSubject<string | null>(null)

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
    const router = inject(Router)
    const httpClient = inject(HttpClient) // Move inject here at the top level

    const handleError = (error: any): Observable<any> => {
        if (!(error instanceof HttpErrorResponse)) {
            return throwError(() => error)
        }

        const skipInterceptor = req.headers.has('skipApiEndPointInterceptor') ||
            req.headers.get(API_URL_HEADER) === ApiType.AUTH ||
            req.url.includes('/auth/login') ||
            req.url.includes('/auth/refresh')

        if (skipInterceptor) {
            return throwError(() => error)
        }

        switch (error.status) {
            case 401:
                return handle401Error(req, next, router, httpClient) // Pass httpClient as parameter
            case 403:
                router.navigate(['/forbidden'])
                return throwError(() => error)
            default:
                return throwError(() => error)
        }
    }

    return next(req).pipe(catchError(handleError))
}

function handle401Error(
    req: HttpRequest<any>,
    next: any,
    router: Router,
    httpClient: HttpClient
): Observable<any> {
    if (!isRefreshing) {
        isRefreshing = true
        tokenRefreshQueue.next(null)

        const refreshToken = localStorage.getItem(MEMBER_MANAGER_REFRESH)
        if (!refreshToken) {
            handleAuthFailure(router)
            return throwError(() => new Error('No refresh token available'))
        }

        return httpClient.post<{access_token: string; refresh_token: string}>(
            'auth/refresh',
            { refresh_token: refreshToken },
            {
                headers: new HttpHeaders({
                    [API_URL_HEADER]: ApiType.AUTH,
                    'Content-Type': 'application/json',
                })
            }
        ).pipe(
            switchMap((tokens) => {

                if (!tokens.access_token || !tokens.refresh_token) {
                    throw new Error('Invalid token response')
                }

                updateTokens(tokens.access_token, tokens.refresh_token)
                isRefreshing = false
                tokenRefreshQueue.next(tokens.access_token)
                return retryRequestWithNewToken(req, next, tokens.access_token)
            }),
            catchError(err => {
                console.error('Refresh token error:', err)
                isRefreshing = false
                tokenRefreshQueue.next(null)
                handleAuthFailure(router)
                return throwError(() => err)
            })
        )
    }

    return tokenRefreshQueue.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(token => retryRequestWithNewToken(req, next, token))
    )
}

function retryRequestWithNewToken(request: any, next: any, token: string): Observable<any> {
    const newReq = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${token}`)
    })
    return next(newReq)
}

function handleAuthFailure(router: Router): void {
    localStorage.removeItem(MEMBER_MANAGER_TOKEN)
    localStorage.removeItem(MEMBER_MANAGER_REFRESH)
    router.navigate(['/auth'], {replaceUrl: true})
}

function updateTokens(accessToken: string, refreshToken: string): void {
    localStorage.setItem(MEMBER_MANAGER_TOKEN, accessToken)
    localStorage.setItem(MEMBER_MANAGER_REFRESH, refreshToken)
}
